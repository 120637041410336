@font-face {
  font-family: DaftPunkfont;
  src: url("DaftFont.aac0738d.ttf");
  font-display: swap;
}

@font-face {
  font-family: FortniteFont;
  src: url("fortnite.f0d75ecb.otf");
  font-display: swap;
}

@font-face {
  font-family: DuolingoFont;
  src: url("Feather Bold.ec809eec.ttf");
  font-display: swap;
}

@media (hover: none) or (width < 650px) {
  #ProfileImage {
    background-image: url("ClickME.357a71a9.png"), var(--pfp);
  }

  .GalleryItem .Text {
    opacity: 1;
    white-space: nowrap;
    padding: 5px;
    background-color: #00000083;
    border-radius: 10px;
    width: fit-content;
    padding-inline: 7.5px;
    font-size: 1.1rem;
  }

  .socials {
    display: none;
  }
}

@media screen and (width <= 1300px) {
  .container {
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 250px;
  }

  #FormFillWrap {
    max-width: 850px;
  }

  .textClass {
    display: none;
  }
}

@media screen and (width <= 930px) {
  .ContactMe {
    height: 35vw;
  }

  .FeedbackText {
    transform: scaleY(1.4);
  }

  #FormFill {
    height: 20vw;
  }
}

@media screen and (width <= 800px) {
  #FormFillWrap {
    max-width: 700px;
  }

  #AboutMeBody {
    width: 60vw;
  }
}

@media (width < 650px) {
  .w-1, .w-2, .w-3, .w-5 {
    grid-column: span 4;
  }

  .h-1, .h-2, .h-3 {
    grid-row: span 1;
  }

  html {
    scroll-behavior: smooth;
    scroll-padding: 15%;
    overflow-x: hidden;
  }

  #smooth {
    padding-top: 6vh;
  }

  #welcomeText {
    font-size: 1.4rem;
  }

  .FeedbackText {
    display: none;
  }

  .InfoContainer {
    height: 60vh;
  }

  #infoWrapper {
    background: none;
    border: none;
    outline: none;
  }

  #HoverClass {
    width: 100%;
    translate: 0;
  }

  #HoverClass:before {
    border-inline: var(--outline);
    border-radius: 30px;
    width: 100%;
  }

  #ProfileImage {
    width: 60vw;
    height: 60vw;
  }

  .container {
    grid-template-columns: 1fr;
    grid-auto-rows: 250px;
  }

  .ContainerTitle {
    font-size: 4rem;
  }

  #GalleryWrap {
    margin-block: 14svh;
  }

  .GalleryContainer:first-child {
    animation: none;
  }

  .viewer-title {
    white-space: normal;
  }

  #FormFill {
    height: 200px;
  }

  .ContactMe {
    height: 350px;
  }

  #FormStatus {
    margin-bottom: 20px;
  }

  #FormFix {
    font-size: 1.75rem;
  }

  #FormFill {
    width: 70vw;
    height: 155px;
    font-size: 1rem;
  }

  #FormButton {
    padding: 20px;
    width: fit-content;
    height: fit-content;
    padding-block: 10px;
    font-size: 1rem;
  }

  .Credit {
    font-size: 1rem;
  }

  .viewer-toolbar > ul {
    height: 30px;
  }

  .viewer-toolbar > ul > .viewer-large {
    width: 30px;
  }

  .alwaysForever {
    font-size: 4rem;
  }

  .button {
    width: 60px;
    height: 60px;
    margin: 6vw;
  }

  .infoBubble {
    background-color: var(--accentColor);
    text-wrap: wrap;
    text-align: left;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    width: 31ch;
    max-width: 60ch;
    display: -webkit-box;
  }

  .infoBubble.classClose {
    opacity: 0;
    max-width: none;
    padding-inline: 0;
    transition: all 1s cubic-bezier(.075, .82, .165, 1), padding 1s .4s;
    scale: 0;
  }
}

@media (width < 400px) {
  #welcomeText {
    font-size: 1.25rem;
  }

  #ProfileImage {
    width: 55vw;
    height: 55vw;
  }

  .NameClass {
    font-size: 2rem;
  }

  .ContainerTitle {
    font-size: 3rem;
  }

  .infoBubble {
    bottom: 8svh;
    right: 8svh;
  }
}
/*# sourceMappingURL=index.e0d82288.css.map */
