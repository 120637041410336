/* body > * {
  overflow-x: hidden;
} */

@font-face {
  font-family: 'DaftPunkfont';
  src: url('customFonts/DaftFont.ttf');
  font-display: swap;
}

@font-face {
  font-family: 'FortniteFont';
  src: url('customFonts/fortnite.otf');
  font-display: swap;
}

@font-face {
  font-family: 'DuolingoFont';
  src: url('customFonts/Feather\ Bold.ttf');
  font-display: swap;
}

@media (hover: none) or (width < 650px) {
  #ProfileImage {
    background-image: url(images/ClickME.png), var(--pfp);
  }

  .GalleryItem .Text {
    opacity: 1;
    /* top: 10%; */
    white-space: nowrap;
    width: fit-content;
    font-size: 1.1rem;
    padding: 5px;
    padding-inline: 7.5px;
    background-color: rgba(0, 0, 0, 0.514);
    border-radius: 10px;
  }

  .socials {
    display: none;
  }
}

@media screen and (max-width: 1300px) {
  .container {
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 250px;
  }

  #FormFillWrap {
    max-width: 850px;
  }

  .textClass {
    display: none;
  }

  /* #FormFill {
    height: 15vw;
  } */
  /* .AboutMe .AboutBody {
    max-height: 1000px;
  } */
}

@media screen and (max-width: 930px) {
  .ContactMe {
    height: 35vw;
  }

  .FeedbackText {
    transform: scaleY(1.4);
  }

  #FormFill {
    /* font-size: 2.3vw; */
    height: 20vw;
  }

  /* #FormStatus {
    font-size: 2vw;
     margin-bottom: 1.5vw;
  } */
}

@media screen and (max-width: 800px) {
  #FormFillWrap {
    max-width: 700px;
  }

  #AboutMeBody {
    width: 60vw;
  }
}

@media (width < 650px) {
  /* .GalleryItem {
    animation: none;
  } */

  .w-1 {
    grid-column: span 4;
  }
  .w-2 {
    grid-column: span 4;
  }
  .w-3 {
    grid-column: span 4;
  }
  .w-5 {
    grid-column: span 4;
  }
  .h-1 {
    grid-row: span 1;
  }
  .h-2 {
    grid-row: span 1;
  }
  .h-3 {
    grid-row: span 1;
  }

  html {
    scroll-behavior: smooth;
    scroll-padding: 15%;
    overflow-x: hidden;
  }

  #smooth {
    padding-top: 6vh;
  }

  #welcomeText {
    font-size: 1.4rem;
  }

  .FeedbackText {
    display: none;
  }
  .InfoContainer {
    height: 60vh;
  }

  #infoWrapper {
    border: none;
    outline: none;
    background: none;
  }
  #HoverClass {
    /* width: 90vw;
    height: 90vw; */
    width: 100%;
    translate: 0 0;
  }
  #HoverClass::before {
    border-inline: var(--outline);
    border-radius: 30px;
    width: 100%;
  }

  #ProfileImage {
    width: 60vw;
    height: 60vw;
  }

  .container {
    grid-template-columns: 1fr;
    grid-auto-rows: 250px;
  }

  .ContainerTitle {
    font-size: 4rem;
  }

  #GalleryWrap {
    margin-block: 14svh;
  }

  .GalleryContainer:nth-child(1) {
    animation: none;
  }

  .viewer-title {
    white-space: normal;
  }

  #FormFill {
    height: 200px;
  }

  .ContactMe {
    height: 320px;
  }

  .ContactMe {
    height: 350px;
  }

  #FormStatus {
    margin-bottom: 20px;
  }
  #FormFix {
    font-size: 1.75rem;
  }

  #FormFill {
    font-size: 1rem;
    width: 70vw;
    height: 155px;
  }
  #FormButton {
    width: fit-content;
    height: fit-content;
    padding: 20px;
    padding-block: 10px;
    font-size: 1rem;
  }
  .Credit {
    font-size: 1rem;
  }

  .viewer-toolbar > ul {
    height: 30px;
  }

  .viewer-toolbar > ul > .viewer-large {
    width: 30px;
  }

  .alwaysForever {
    font-size: 4rem;
  }

  .button {
    margin: 6vw;
    width: 60px;
    height: 60px;
  }

  .infoBubble {
    background-color: var(--accentColor);
    /* position: fixed; */
    /* z-index: 5; */
    width: 31ch;
    max-width: 60ch;
    /* white-space: nowrap; */
    display: -webkit-box;
    text-wrap: wrap;
    text-align: left;
    /* text-overflow: ellipsis; */
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    /* transform-origin: center; */
  }

  .infoBubble.classClose {
    scale: 0;
    max-width: none;
    transition: 1s cubic-bezier(0.075, 0.82, 0.165, 1), padding 1s 0.4s;
    padding-inline: 0;
    opacity: 0;
    /* padding-inline: 0; */
  }
}

@media (width < 400px) {
  #welcomeText {
    font-size: 1.25rem;
  }
  #ProfileImage {
    width: 55vw;
    height: 55vw;
  }
  .NameClass {
    font-size: 2rem;
  }

  .ContainerTitle {
    font-size: 3rem;
  }

  .infoBubble {
    bottom: 8svh;
    right: 8svh;
  }
}
